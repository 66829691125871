import { Directive, EventEmitter, OnDestroy, Output, QueryList, ViewChildren, ViewContainerRef } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { environment } from '@env/environment';
import { NgxTurnstileComponent } from 'ngx-turnstile';
import { Subject, takeUntil } from 'rxjs';

/*
 */
@Directive()
export class ViewAuthDefaultComponent implements OnDestroy {
  protected readonly destroyed$ = new Subject<boolean>();
  @Output() on_action = new EventEmitter<object>();

  /*
   */
  @ViewChildren('Captcha', { read: ViewContainerRef })
  protected captchaList: QueryList<ViewContainerRef>;
  protected captchaKey = environment.re_captcha_site_key;
  protected captchaResolved = new EventEmitter();
  public captchaVisible = false;
  public captchaToken = null;

  /*
   */
  public step = 0;
  public steps = 0;
  protected step_payload = null;

  /*
   */
  protected form: UntypedFormGroup;
  showValidation = false;
  action_error = null;

  /*
   */
  constructor(
    protected fb: UntypedFormBuilder,
    protected captcha: boolean = false,
    protected ZodEndpoint: any /* Todo. type of ZOD LoginEndpoint, etc */,
  ) {
    this.createForm();
  }

  /*
   */
  ngOnDestroy(): void {
    this.captcha_remove();
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  /*
   */
  public emit = (payload: any) => this.on_action.emit({ ...payload, action: this.ZodEndpoint?.action });
  public on_ok = (payload: any) => {
    this.showValidation = false;
    this.emit(payload);
  };
  public on_err = (payload: any) => {
    this.showValidation = true;
    this.emit(payload);
    this.on_validation_err(payload.action, payload.error);
  };
  public on_fin = (payload: any) => {
    this.reset();
    this.emit(payload);
  };

  /*
   */
  public set_view(val: string) {
    this.emit({ set_view: val });
  }

  /*
   */
  protected set_max_steps(val: number) {
    this.steps = val;
  }

  protected step_next(payload: any) {
    this.step_payload = payload;
    return this.step >= this.steps ? (this.step = this.steps) : ++this.step;
  }

  protected step_prev(payload: any) {
    this.step_payload = payload;
    return this.step <= 0 ? (this.step = 0) : --this.step;
  }

  /*
   */
  protected createForm(): void {
    this.form = this.fb.group({});
  }

  /* Maybe create Captcha component
   */
  private captcha_remove() {
    this.captchaVisible = false;
    this.captchaList.map((view: ViewContainerRef) => {
      if (!view) {
        return;
      }
      if (view['__comp_ptr']) {
        view['__comp_ptr'].destroy();
      }
      view.clear();
    });
  }

  /* Maybe create Captcha component
   */
  private captcha_load(action: string) {
    this.captcha_remove();
    this.captchaResolved = null; // Force GC
    this.captchaResolved = new EventEmitter();
    this.captchaList.map((view: ViewContainerRef) => {
      view.clear();
      const el = view.element.nativeElement;

      if (el.getAttribute('action') !== action) {
        return;
      }

      this.captchaVisible = true;
      const comp_ref = view.createComponent<NgxTurnstileComponent>(NgxTurnstileComponent);
      comp_ref.instance.siteKey = this.captchaKey;
      comp_ref.instance.resolved = this.captchaResolved;
      comp_ref.instance.action = action;
      view['__comp_ptr'] = comp_ref;
    });

    return this.captchaResolved;
  }

  /*
   */
  public setCaptchaResolved(e: any) {}

  /*
   */
  protected get_err = (mode, err): string => `auth.errors.${mode}.${err}`;
  protected mapError = (mode, err): string => `auth.errors.${mode}.${err}`; // temp

  /*
   */
  protected on_validation_err(type: string, errs: any) {
    const required = errs.find(el => el.code === 'custom');
    const first = errs[0];

    this.action_error = this.mapError(type, required ? 'required': first?.message);
    errs.map((err_el) => {
      const prop = err_el.path?.[0];
      if (prop) {
        this.form.controls[prop]?.setErrors({ error: err_el.message });
      }
    });
  }

  /*
   */
  protected reset() {
    this.captcha_remove();
    this.showValidation = false;
    this.captchaToken = null;
  }

  /*
   */
  protected process_form(payload: any, callback?: (res: any) => void): void {
    this.action_error = null;
    this.reset();

    try {
      this.ZodEndpoint.parseBody(payload);
      console.debug(`@@@: `, payload);
    } catch (err) {
      console.debug(`@@@: ViewAuthDefaultComponent.process_form: `, err);
      const errs_list = err?.issues || err?.validationError; // front or back resp
      this.on_validation_err(this.ZodEndpoint.action, errs_list);
      this.showValidation = true;
      return;
    }

    const res = { action: this.ZodEndpoint.action, payload };

    // Captcha
    if (this.captcha === true) {
      this.captcha_load(this.ZodEndpoint.action)
        .pipe(takeUntil(this.destroyed$))
        .subscribe((captchaToken) => {
          this.emit({ ...res, captchaToken });
          if (callback) {
            callback({ ...res, captchaToken });
          }
        });
      return;
    }

    // No catpcha
    this.emit(res);
    if (callback) {
      callback(res);
    }
    return;
  }
}
