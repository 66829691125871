<div class="view">
    <app-auth-input-field class="form__input"
                        [form]="form"
                        dataTestName="email"
                        type="email"
                        [placeholder]="'auth.email'"
                        controlName="email"
                        [showValidation]="showValidation"
                        (onEnter)="submit()">
    </app-auth-input-field>

    <app-auth-input-field  class="form__input"
                        [form]="form"
                        dataTestName="password"
                        type="password"
                        [placeholder]="'auth.password'"
                        controlName="password"
                        [showValidation]="showValidation"
                        (onEnter)="submit()">
    </app-auth-input-field>
    <a class="inline-href"
        (click)="set_view('restore')">
        {{ 'auth.signin.forgot-password' | translate }}
    </a>

    <!-- Captcha -->
    <div class="view__captcha"><div #Captcha action="signin"></div></div>

    <!-- Submit -->
    <button *ngIf="!captchaVisible"
            data-test="submit"
            type="button" class="button signin normal"
            (click)="submit()"> {{ 'auth.signin.action1' | translate }}
    </button>

    <!-- Create account -->
    <button *ngIf="!captchaVisible" (click)="set_view('signup')"
            type="button"
            class="button button--slave">
        {{ 'auth.signin.create-new-account' | translate }}
    </button>

    <!-- Error -->
    <div class="error-content" *ngIf="action_error">{{ action_error | translate }}</div>

    <!-- SSO -->
    <div class="block" *ngIf="userCountry !== 'RU' && !captchaVisible">
        <h6>{{'auth.signin.action2' | translate}}</h6>
        <div class="form-content signin-social">
          <div (click)="ssoFacebook()"
            class="button__slave--icon" style="color: #3c589e">
            <img class="icon__def" src="/assets/icons/v2/auth-facebook.svg" />
            Facebook
          </div>

          <asl-google-signin-button type="standard" size="large" width="155"></asl-google-signin-button>
        </div>
    </div>
</div>
