import { Component } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { AccountService, AuthService, ConfigService, IconsService } from '@app/core/services';
import { IControlOption } from '@app/shared';
import { EnumAudience, SignUpEndpoint } from 'lingo2-api-models';
import { Country } from 'lingo2-api-models';
import { ISignupRequest, TeacherStatusEnum, UserSegmentEnum } from 'lingo2-models';
import { combineLatest, takeUntil } from 'rxjs';
import { ViewAuthDefaultComponent } from '../view-auth-default.component';

/*
 */
@Component({
  selector: 'view-auth-signup',
  templateUrl: './view-auth-signup.component.html',
  styleUrls: ['./view-auth-signup.component.scss'],
})
export class ViewAuthSignUpComponent extends ViewAuthDefaultComponent {
  protected svgSetIcon = IconsService.svgsetIconUrl;

  public TEACHER = TeacherStatusEnum.teacher;
  public STUDENT = TeacherStatusEnum.not_teacher;
  public roleTypes: Array<{ role: number; label: string; icon_id: string }> = [
    { role: TeacherStatusEnum.not_teacher, label: 'auth.signup.student', icon_id: 'student' },
    { role: TeacherStatusEnum.teacher, label: 'auth.signup.teacher', icon_id: 'teacher' },
  ];
  public role: TeacherStatusEnum = TeacherStatusEnum.not_teacher;
  public countriesList: IControlOption[] = [];
  public user_country: string;

  /*
   */
  constructor(
    protected fb: UntypedFormBuilder,
    private accountService: AccountService,
    private authService: AuthService,
    private cfg_srv: ConfigService,
  ) {
    super(fb, true, SignUpEndpoint);

    this.cfg_srv.countries$.pipe(takeUntil(this.destroyed$)).subscribe((res) => {
      this.countriesList = res
        .map((c: Country) => ({ title: this.country_title(c), value: c.id.toString() }))
        .sort((a, b) => a.title.localeCompare(b.title));
    });
    this.createForm();
    this.set_max_steps(1);
  }

  /*
   */
  private country_title(country: Country) {
    const parts: string[] = [country.title_native];
    if (country.title && country.title_native !== country.title) {
      parts.push(`(${country.title})`);
    }
    return parts.join(' ');
  }

  /*
   */
  public trackByFn = (index) => index;

  /*
   */
  protected createForm(): void {
    this.form = this.fb.group({
      email: [''],
      password: [''],
      teacher_status: [null],
      first_name: [''],
      last_name: [''],
      country_id: [''],
      mobile_phone: [''],
      slug: [''],
      accept_sla: [false],
    });
  }

  /*
   */
  public set_role(role: TeacherStatusEnum) {
    this.role = role;
  }

  /*
   */
  public submit() {
    const ctrls = this.form.controls;
    const payload = {
      teacher_status: this.role,
      accept_sla: ctrls.accept_sla.value,
      audience: EnumAudience.web,
      email: ctrls.email.value,
      password: ctrls.password.value,
      first_name: ctrls.first_name.value,
      last_name: ctrls.last_name.value,
      mobile_phone: ctrls.mobile_phone.value?.e164Number,
      country_id: ctrls.country_id?.value,
      slug: ctrls.slug?.value,
    };
    if (this.role === TeacherStatusEnum.not_teacher) {
      delete payload.slug;
    }

    this.process_form(payload, (result) => {
      this.authService
        .signup(result.payload as SignUpEndpoint.Body, result?.captchaToken)
        .then((auth_res) => {
          this.addSegments(result.payload);
          this.on_ok({ success: auth_res });
        })
        .catch((err) => this.on_err({ action: SignUpEndpoint.action, error: err }))
        .finally(() => this.on_fin({}));
    });
  }

  private addSegments(payload: ISignupRequest) {
    if (payload.teacher_status === (2 as TeacherStatusEnum)) {
      combineLatest([
        this.accountService.removeSegment(UserSegmentEnum.as_if_teacher),
        this.accountService.removeSegment(UserSegmentEnum.as_if_student),
        this.accountService.addSegment(UserSegmentEnum.as_if_methodist),
      ])
        .pipe(takeUntil(this.destroyed$))
        .subscribe();
    } else if (payload.teacher_status === TeacherStatusEnum.teacher) {
      combineLatest([
        this.accountService.removeSegment(UserSegmentEnum.as_if_methodist),
        this.accountService.removeSegment(UserSegmentEnum.as_if_student),
        this.accountService.addSegment(UserSegmentEnum.as_if_teacher),
      ])
        .pipe(takeUntil(this.destroyed$))
        .subscribe();
    } else if (payload.teacher_status === TeacherStatusEnum.not_teacher) {
      combineLatest([
        this.accountService.removeSegment(UserSegmentEnum.as_if_methodist),
        this.accountService.removeSegment(UserSegmentEnum.as_if_teacher),
        this.accountService.addSegment(UserSegmentEnum.as_if_student),
      ])
        .pipe(takeUntil(this.destroyed$))
        .subscribe();
    }
  }
}
