import {
  FacebookLoginProvider,
  GoogleLoginProvider,
  SocialAuthService,
  SocialUser,
} from '@abacritt/angularx-social-login';
import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { AuthService } from '@app/core/services';
import { FacebookAppId, GoogleClientId } from '@app/core/services/social-auth.service';
import { EnumAudience, LogInEndpoint } from 'lingo2-api-models';
import { ISocialSignupRequest, SocialNetworkEnum } from 'lingo2-models';
import { takeUntil } from 'rxjs';
import { ViewAuthDefaultComponent } from '../view-auth-default.component';
/*
 */
@Component({
  selector: 'view-auth-signin',
  templateUrl: './view-auth-signin.component.html',
  styleUrls: ['./view-auth-signin.component.scss'],
})
export class ViewAuthSignInComponent extends ViewAuthDefaultComponent {
  public userCountry = null;
  todo_google_button_tricky_shit = false;

  /*
   */
  constructor(protected fb: UntypedFormBuilder, private authService: AuthService, private sso_srv: SocialAuthService) {
    super(fb, true, LogInEndpoint);
    this.createForm();
    this.userCountry = this.authService.getUserGeo();

    // SSO callback
    this.sso_srv.authState.pipe(takeUntil(this.destroyed$)).subscribe({
      next: (user: SocialUser) => {
        this.ssoFinish(user);
      },
      error: (err) => {
        this.on_err({ action: LogInEndpoint.action, error: [{ message: `Something wrong while login...` }] });
      },
    });

    // TODO! Tricky-shitty stuff. Button appears only after second opening. No idea why
    this.todo_google_button_tricky_shit = true;
  }

  /*
   */
  protected createForm(): void {
    this.form = this.fb.group({ email: [''], password: [''] });
  }

  /* SSO Facebook. Google SSO processing via 'google-button'
   */
  public ssoFacebook() {
    this.sso_srv.signIn(FacebookLoginProvider.PROVIDER_ID).then();
  }

  /* SSO finish
   */
  protected ssoFinish(user: SocialUser) {
    if (!user) {
      this.on_err({ action: LogInEndpoint.action, error: [{ message: `Something wrong while login...` }] });
      return;
    }

    let { network, network_app_id, access_token } = {} as any;

    switch (user.provider) {
      case GoogleLoginProvider.PROVIDER_ID:
        network = SocialNetworkEnum.google;
        network_app_id = GoogleClientId;
        access_token = user.idToken;
        break;

      case FacebookLoginProvider.PROVIDER_ID:
        network = SocialNetworkEnum.facebook;
        network_app_id = FacebookAppId;
        access_token = user.authToken;
        break;

      default:
        return;
    }

    this.authService
      .ssoLogin({
        network,
        network_app_id,
        network_account_id: user.id,
        email: user.email,
        first_name: user.firstName,
        last_name: user.lastName,
        picture: user.photoUrl,
        access_token,
      } as ISocialSignupRequest)
      .then((auth_res) => this.on_ok({ success: auth_res }))
      .catch((err) => this.on_err({ action: LogInEndpoint.action, error: err }))
      .finally(() => this.on_fin({}));
  }

  /*
   */
  public submit() {
    this.process_form(
      {
        email: this.form.controls.email.value,
        password: this.form.controls.password.value,
        audience: EnumAudience.web,
      },
      (result) => {
        this.authService
          .signIn(result.payload as LogInEndpoint.Body, result?.captchaToken)
          .then((auth_res) => this.on_ok({ success: auth_res }))
          .catch((err) => this.on_err({ action: LogInEndpoint.action, error: err }))
          .finally(() => this.on_fin({}));
      },
    );
  }
}
